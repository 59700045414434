<template>
  <v-card class="mt-2 pb-2 card_background" width="100%" max-width="400px">
    <v-img
      height="250"
      class="grey darken-4 white--text align-center text-center"
      :src="img"
      contain
    >
      <h3 class="text-h6">Preview del producto</h3>
    </v-img>

    <v-card-title class="text-h4 pb-0">{{ title }} </v-card-title>

    <v-card-text>
      <v-row align="center" class="mx-0">
        <v-rating
          :value="4.5"
          color="amber"
          dense
          half-increments
          readonly
          size="14"
        ></v-rating>

        <div class="grey--text ml-4">
          5 (320)
        </div>
      </v-row>

      <div class="subtitle-1 mt-2 mb-2">$ {{ price }} MXN</div>

      <div class="text-body-2">
        {{ description }}
      </div>
      <v-card-actions class="justify-space-between px-0">
        <div class="d-flex align-center">
          <v-chip class="px-3">
            <v-icon size="10">remove</v-icon>
          </v-chip>
          <v-chip class="ma-1 px-4" color="primary">
            {{ inventory }}
          </v-chip>
          <v-chip class="px-3">
            <v-icon size="10">add</v-icon>
          </v-chip>
        </div>
        <v-btn color="error">
          Comprar
        </v-btn>
      </v-card-actions>
    </v-card-text>
    <v-divider class="mx-4"></v-divider>
    <v-card-actions class="justify-end">
      <v-btn icon>
        <v-img src="@/assets/social/facebook-icon.png" width="35px"></v-img>
      </v-btn>
      <v-btn icon>
        <v-img src="@/assets/social/instagram-icon.png" width="35px"></v-img>
      </v-btn>
      <v-btn icon>
        <v-img src="@/assets/social/twitter-icon.png" width="35px"></v-img>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      default: ""
    },
    price: {
      default: 0
    },
    description: {
      default: ""
    },
    img: {
      default: ""
    },
    inventory: {
      default: 0
    }
  }
};
</script>

<style></style>
