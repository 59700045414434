<template>
  <v-container :class="{ 'pr-0 pl-0 pt-0': !$vuetify.breakpoint.mdAndUp }">
    <v-row>
      <v-col cols="12" class="py-1">
        <h3 class="text-h5 ml-1 font-weight-medium">
          Registro de productos
        </h3>
      </v-col>
      <v-col cols="12" sm="12" md="7" lg="6" xl="5" offset-lg="1" offset-xl="2">
        <v-form ref="productForm">
          <SearchProductShallow typeAction="register" ref="searchCode" />
          <v-card width="100%" class="mt-2 mb-2 card_background">
            <v-container class="pb-0">
              <v-row>
                <v-col cols="12" class="pb-0 pt-0">
                  <h3 class="text-h6">Nombre</h3>
                  <v-text-field
                    label="Nombre del producto"
                    v-model="nameProduct"
                    :rules="[requiredRule]"
                    outlined
                    @keyup.enter.native="$refs.description.focus()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pt-0 pb-0">
                  <v-textarea
                    v-model="description"
                    ref="description"
                    :rules="[requiredRule]"
                    outlined
                    rows="1"
                    auto-grow
                    label="Descripción"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
          <v-card width="100%" class="mt-2 mb-2 card_background" ref="category">
            <app-categories></app-categories>
          </v-card>
          <v-card width="100%" class="mt-2 mb-2 card_background">
            <v-container>
              <v-row>
                <v-col cols="12" class="pt-0 pb-0">
                  <h3 class="text-h6">Precio</h3>
                  <v-text-field
                    v-model="price"
                    :rules="[requiredRule]"
                    outlined
                    label="Precio al publico"
                    prepend-inner-icon="attach_money"
                    type="number"
                    @keyup.enter.native="$refs.costPrice.focus()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pt-0 pb-0">
                  <v-text-field
                    v-model="costPrice"
                    :rules="[requiredRule]"
                    ref="costPrice"
                    outlined
                    label="Costo por producto"
                    type="number"
                    persistent-hint
                    hint="Los clientes no verán esta información"
                    prepend-inner-icon="attach_money"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="text-center pt-0 pb-0">
                  <p class="ma-0">Margen</p>
                  <p class="ma-0">{{ marginCost }} %</p>
                </v-col>
                <v-col cols="6" class="text-center pt-0 pb-0">
                  <p class="ma-0">Ganancia</p>
                  <p class="ma-0">$ {{ roundFloatNumber(profit) }}</p>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" class="pt-0 pb-0">
                  <div class="mt-1 mb-2 justify-end d-flex">
                    <v-switch
                      v-model="wholesalePriceCheck"
                      label="Mayoreo"
                      color="primary"
                      dense
                      class="mt-0 mr-1"
                      hide-details
                    ></v-switch>
                  </div>
                </v-col>
                <v-col cols="12" class="pt-0 pb-0" v-if="wholesalePriceCheck">
                  <p class="mb-1">
                    Agregue la cantidad de productos y el precio que desea
                    aplicar a una venta por mayoreo de este producto
                  </p>
                  <template v-for="(wholesale, index) in wholesales">
                    <div class="d-flex" :key="index + '-whosale'">
                      <v-text-field
                        ref="minimumProducts"
                        v-model.number="wholesale.minimum_products"
                        :rules="[requiredRule, noSpecialCharactersRule]"
                        outlined
                        label="Cantidad minima"
                        type="number"
                        class="mr-1"
                      ></v-text-field>
                      <v-text-field
                        v-model.number="wholesale.wholesale_price"
                        :rules="[requiredRule, noSpecialCharactersRule]"
                        outlined
                        label="Precio mayoreo"
                        type="number"
                        class="ml-1"
                      ></v-text-field>
                      <v-btn
                        v-if="index != 0"
                        fab
                        x-small
                        class="mt-3 ml-1"
                        @click="removeWhosale(index)"
                        ><v-icon>close</v-icon></v-btn
                      >
                    </div>
                  </template>
                </v-col>
                <v-col cols="12" class="pt-0 pb-0" v-if="wholesalePriceCheck">
                  <v-btn color="primary" block @click="addWhosale"
                    >Agregar más campos</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-card>
          <v-card width="100%" class="mt-2 mb-2 card_background">
            <v-container class="pb-0">
              <v-row>
                <v-col cols="12" class="pt-0 pb-0" ref="barcodeResult">
                  <div class="d-flex align-center justify-space-between">
                    <h3 class="text-h6">Inventario</h3>
                    <v-switch
                      class="ma-0 mb-1"
                      v-model="isInventoried"
                      dense
                      color="primary"
                      hide-details
                    ></v-switch>
                  </div>
                </v-col>
                <v-col cols="12" class="pb-0 pt-0">
                  <v-card elevation="0" v-if="!isInventoried">
                    <v-container class="pt-0">
                      <p class="ma-0 mt-2 caption">
                        si deseas habilitar el inventariado de este producto
                        activa el switch de arriba y completa la información
                        correspondiente.
                      </p>
                    </v-container>
                  </v-card>
                </v-col>
                <v-col v-if="isInventoried" class="pb-0 pt-0">
                  <p class="ma-0 mb-1">
                    Complete la siguiente información si desea registrar un
                    inventario inicial para el registro de este producto
                  </p>
                </v-col>
                <v-col cols="12" class="pb-0 pt-1" v-if="isInventoried">
                  <v-text-field
                    v-model.number="totalInventory"
                    :rules="[requiredRule, noSpecialCharactersRule]"
                    ref="totalInventory"
                    label="Cantidad total de productos"
                    type="number"
                    outlined
                  ></v-text-field>

                  <v-text-field
                    v-model.number="minInventory"
                    :rules="[requiredRule, noSpecialCharactersRule]"
                    ref="minInventory"
                    label="Cantidad minima de inventario"
                    type="number"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pt-0 pb-0" v-if="isInventoried">
                  <p>
                    Seleccione la sucursal en la cual deseas registrar el
                    inventario inicial
                    <span class="text--secondary caption"
                      >(Si tienes más de una sucursal automáticamente se
                      habilita la opción de inventario para este producto con el
                      “total de producto” en 0)</span
                    >
                  </p>
                  <v-select
                    v-model="currentBranchSelected"
                    :rules="[requiredRule]"
                    item-text="branch_office_name"
                    item-value="id"
                    :items="branchOffice"
                    label="Seleccione la sucursal"
                    outlined
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
          <v-card width="100%" class="mt-2 mb-2 card_background">
            <v-container>
              <v-row>
                <v-col cols="12" class="pt-0">
                  <h3 class="text-h6">Imagen</h3>
                  <p class="ma-0">
                    Captura o Adjunta una imagen para este producto
                  </p>
                </v-col>
                <v-col cols="12" class="text-center pt-0">
                  <v-card @click="uploadImage" elevation="0">
                    <app-upload-images ref="uploadImages"></app-upload-images>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-form>
      </v-col>
      <v-col cols="12" sm="12" md="5" lg="4" xl="3" class="pt-0 pb-0">
        <app-register-preview
          v-if="$vuetify.breakpoint.mdAndUp"
          :title="nameProduct"
          :description="description"
          :price="price"
          :img="imageData.imageSrc"
          :inventory="totalInventory"
        ></app-register-preview>
        <v-expansion-panels
          v-if="!$vuetify.breakpoint.mdAndUp"
          v-model="previewPanel"
        >
          <v-expansion-panel :key="0">
            <v-expansion-panel-header class="card_background">
              Ver preview del producto
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <app-register-preview
                v-if="previewPanel === 0"
                :title="nameProduct"
                :description="description"
                :price="price"
                :img="imageData.imageSrc"
                :inventory="totalInventory"
              ></app-register-preview>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-row v-if="$vuetify.breakpoint.mdAndUp" class="text-right">
          <v-container class="pb-0">
            <v-col cols="12" class="pr-0">
              <v-btn dark @click="register" :disabled="loadImage"
                >Guardar Producto</v-btn
              >
            </v-col>
          </v-container>
        </v-row>
      </v-col>
      <v-row v-if="!$vuetify.breakpoint.mdAndUp">
        <v-col cols="12" class="pt-0 pb-0">
          <v-container class="pb-0">
            <v-btn
              dark
              block
              @click="register"
              :disabled="loadImage"
              color="primary"
              >Guardar Producto</v-btn
            >
          </v-container>
        </v-col>
      </v-row>
    </v-row>
    <!-- <app-print-bar-code
      :numElementRender="printElementRender"
    ></app-print-bar-code> -->
  </v-container>
</template>

<script>
import Preview from "@/components/inventory/register/Preview";
import UploadImages from "../components/app/UploadImages";
import Categories from "../components/products/components/Categories";
import { mapActions, mapGetters, mapState } from "vuex";
import { notSpecialCharacters, required } from "@/services/ValidatorService";
import SearchProductShallow from "@/components/inventory/SearchProductShallow.vue";
import moment from "moment";
// import PrintBarCode from "@/components/app/TemplateBarCodePrint";
// const JsBarcode = require("jsbarcode");

export default {
  components: {
    AppRegisterPreview: Preview,
    AppUploadImages: UploadImages,
    AppCategories: Categories,
    SearchProductShallow
    // AppPrintBarCode: PrintBarCode
  },
  data() {
    return {
      // :: Form
      requiredRule: required,
      noSpecialCharactersRule: notSpecialCharacters,
      nameProduct: null,
      description: null,
      price: null,
      wholesalePriceCheck: false,
      wholesales: [
        {
          wholesale_price: null,
          minimum_products: null
        }
      ],
      costPrice: null,
      barcode: "",
      isInventoried: false,
      totalInventory: null,
      minInventory: null,
      currentBranchSelected: null,
      previewPanel: false
    };
  },
  beforeDestroy() {
    this.resetRegisterProduct();
  },
  async beforeMount() {
    await this.getBranchOfficeData();
    this.currentBranchSelected = this.currentBranchID;
  },
  computed: {
    ...mapGetters("products", ["getLastCategorySelected"]),
    ...mapState("business", ["branchOffice", "currentBranchID"]),
    ...mapState("app", ["imageData", "loadImage", "searchBarcodeResult"]),
    marginCost() {
      let margin = ((this.price - this.costPrice) / this.price) * 100;
      if (margin) return this.roundFloatNumber(margin);
      else return 0;
    },
    profit() {
      return this.price - this.costPrice;
    }
  },
  methods: {
    ...mapActions("business", ["getBranchOfficeData"]),
    ...mapActions("app", ["resetRegisterProduct"]),
    ...mapActions("products", ["uploadProduct"]),
    async register() {
      const data = {
        name_product: this.nameProduct,
        description: this.description,
        price: parseFloat(this.price),
        cost_price: parseFloat(this.costPrice),
        barcode: this.searchBarcodeResult,
        product_id: this.searchBarcodeResult,
        created: moment().unix(),
        branch_office: {},
        category: this.getLastCategorySelected
      };
      let totalMoneyPrice = 0;
      let totalMoney = 0;
      if (this.isInventoried) {
        totalMoney = parseInt(this.totalInventory) * parseFloat(this.costPrice);
        totalMoneyPrice =
          parseInt(this.totalInventory) * parseFloat(this.price);
        this.branchOffice.forEach(branch => {
          if (branch.id === this.currentBranchSelected) {
            data.branch_office[branch.id] = {
              name: branch.branch_office_name,
              inventory: {
                total_inventory: parseInt(this.totalInventory),
                min_inventory: parseInt(
                  this.minInventory ? this.minInventory : 0
                )
              },
              metadata: {
                last_updated: moment().unix()
              }
            };
          } else {
            data.branch_office[branch.id] = {
              name: branch.branch_office_name,
              inventory: {
                total_inventory: 0,
                min_inventory: parseInt(
                  this.minInventory ? this.minInventory : 0
                )
              },
              metadata: {
                last_updated: moment().unix()
              }
            };
          }
        });
      } else {
        this.branchOffice.forEach(branch => {
          data.branch_office[branch.id] = {
            name: branch.branch_office_name,
            metadata: {
              last_updated: moment().unix()
            }
          };
        });
      }
      if (this.wholesalePriceCheck) {
        data.wholesale = this.wholesales;
      }
      if (this.$refs.productForm.validate()) {
        if (this.getLastCategorySelected) {
          if (this.imageData.blob) {
            data.photoData = this.imageData.blob;
          }
          await this.uploadProduct({
            data,
            isInventoried: this.isInventoried,
            totalInventory: this.totalInventory,
            totalMoneyPrice: this.roundFloatNumber(totalMoneyPrice),
            totalMoney: this.roundFloatNumber(totalMoney),
            currentBranch: this.currentBranchSelected
          });
          this.$vuetify.goTo(0);
          this.resetFields();
          this.resetRegisterProduct();
        } else {
          this.$vuetify.goTo(this.$refs.category, { offset: 10 });
        }
      } else {
        this.$vuetify.goTo(this.$refs.productForm);
      }
    },
    uploadImage() {
      this.$refs.uploadImages.$refs.upload.click();
    },
    resetFields() {
      this.nameProduct = null;
      this.description = null;
      this.price = null;
      this.wholesalePriceCheck = false;
      this.wholesales = [
        {
          minimum_products: null,
          wholesale_price: null
        }
      ];
      this.isInventoried = false;
      this.costPrice = null;
      this.barcode = "";
      this.totalInventory = null;
      this.minInventory = null;
      this.$refs.productForm.resetValidation();
      this.$refs.searchCode.resetCode();
    },
    addWhosale() {
      this.wholesales.push({
        minimum_products: null,
        wholesale_price: null
      });
    },
    roundFloatNumber(num) {
      return Math.round((num + Number.EPSILON) * 100) / 100;
    },
    // showBarcodeMethod() {
    //   if (this.barcode) JsBarcode("#barcode", this.barcode);
    // },
    // printBarCode() {
    //   if (this.barcode) {
    //     JsBarcode(".barcode-print", this.barcode, {
    //       height: 75,
    //       fontSize: 16
    //     });
    //     // Get HTML to print from element
    //     const prtHtml = document.getElementById("print-component").innerHTML;
    //     // Open the print window
    //     const WinPrint = window.open(
    //       "",
    //       "",
    //       "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
    //     );
    //     WinPrint.document.write(`<!DOCTYPE html>
    //     <html>
    //       <body>
    //         ${prtHtml}
    //       </body>
    //     </html>`);
    //     WinPrint.document.close();
    //     WinPrint.focus();
    //     WinPrint.print();
    //     WinPrint.close();
    //   }
    // },
    removeWhosale(index) {
      this.wholesales.splice(index, 1);
    }
  }
};
</script>

<style scoped>
.dropZone {
  border: 1px dashed rgb(0, 0, 0);
  -webkit-border-radius: 5px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}
</style>
