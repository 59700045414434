<template>
  <v-container class="pb-0">
    <v-row>
      <v-col cols="12" class="pb-0 pt-0">
        <h3 class="text-h6">Categoría</h3>
        <p class="ma-0 mb-1">
          Selecciona la categoría a la cual pertenece tu producto
        </p>
      </v-col>
      <v-col cols="12" class="pt-1">
        <v-slide-x-reverse-transition leave-absolute>
          <v-card
            class="overflow-y-auto mb-2"
            max-height="300"
            outlined
            v-if="
              getRootCategories.length > 0 && stepCategorie === 1 && !loader
            "
          >
            <v-list dense>
              <v-list-item-group color="primary">
                <v-list-item
                  v-for="(categorie, index) in getRootCategories"
                  :key="categorie.id"
                  @click="
                    nextCategory(
                      categorie.id,
                      index,
                      getRootCategories.length,
                      categorie
                    )
                  "
                >
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="categorie.name"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-slide-x-reverse-transition>
        <v-slide-x-reverse-transition leave-absolute>
          <v-card
            class="overflow-y-auto mb-2"
            max-height="300"
            outlined
            v-if="
              !loader &&
                stepCategorie === 2 &&
                getSubCategories(subCategoryID).length > 0
            "
          >
            <p class="font-weight-bold mt-2 mb-0 text-center">
              "{{ titleCategory }}"
            </p>
            <v-list dense>
              <v-list-item-group color="primary">
                <v-list-item
                  v-for="(categorie, index) in getSubCategories(subCategoryID)"
                  :key="categorie.id"
                  @click="
                    nextSubCategory(
                      index,
                      getSubCategories(subCategoryID).length,
                      categorie
                    )
                  "
                >
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="categorie.name"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-slide-x-reverse-transition>
        <v-slide-x-reverse-transition leave-absolute>
          <v-card
            class="overflow-y-auto mb-2"
            max-height="300"
            outlined
            v-if="!loader && stepCategorie === 3"
          >
            <v-container class="text-center">
              <v-img
                :src="categorie.picture"
                v-if="categorie.picture"
                width="80"
                height="80"
                style="margin: 0 auto;"
              >
                <template v-slot:placeholder>
                  <v-row class="ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <h4 class="text-subtitle-1 mb-2">{{ categorie.name }}</h4>

              <v-btn small dark @click="stepCategorie = 1">cambiar</v-btn>
            </v-container>
          </v-card>
        </v-slide-x-reverse-transition>
        <v-card
          height="300"
          elevation="0"
          class="d-flex align-center justify-center"
          v-if="loader"
        >
          <p class="ma-0 mt-5">Cargando categorias...</p>
          <app-absolute-loader
            :progress="loader"
            :color="$vuetify.theme.dark ? '' : 'grey'"
          ></app-absolute-loader>
        </v-card>
        <div class="text-center" v-if="!loader">
          <v-btn
            v-if="stepCategorie === 2"
            @click="goBackCategory"
            small
            class="ma-2"
            ><v-icon size="20" class="mr-1">arrow_back</v-icon> regresar</v-btn
          >
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import AbsoluteLoader from "@/components/layout/AbsoluteLoader";

export default {
  data() {
    return {
      categorie: null,
      titleCategory: "",
      subCategoryID: null,
      stepCategorie: 1,
      loader: false
    };
  },
  computed: {
    ...mapState("products", ["categories"]),
    ...mapGetters("products", ["getRootCategories", "getSubCategories"])
  },
  components: {
    AppAbsoluteLoader: AbsoluteLoader
  },
  mounted() {
    if (this.categories.length === 0) this.getCategoriesData();
  },
  beforeDestroy() {
    this.changeCategorySelected(null);
  },
  methods: {
    ...mapActions("products", ["getCategories", "changeCategorySelected"]),
    async getCategoriesData() {
      this.loader = true;
      await this.getCategories();
      this.loader = false;
    },
    nextCategory(categorieID, index, lengthC, categorie) {
      this.categorie = categorie;
      this.titleCategory = categorie.name;
      if (index + 1 < lengthC) {
        this.subCategoryID = categorieID;
        this.stepCategorie = 2;
      } else {
        this.changeCategorySelected({
          id: this.categorie.id,
          name: this.categorie.name
        });
        this.stepCategorie = 3;
      }
    },
    nextSubCategory(index, lengthC, categorie) {
      if (index + 1 < lengthC) {
        this.categorie = categorie;
        this.changeCategorySelected({
          id: categorie.id,
          name: categorie.name
        });
        this.stepCategorie = 3;
      } else {
        this.changeCategorySelected({
          id: this.categorie.id,
          name: this.categorie.name
        });
        this.stepCategorie = 3;
      }
    },
    goBackCategory() {
      this.stepCategorie--;
    }
  }
};
</script>

<style></style>
