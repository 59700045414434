var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pb-0"},[_c('v-row',[_c('v-col',{staticClass:"pb-0 pt-0",attrs:{"cols":"12"}},[_c('h3',{staticClass:"text-h6"},[_vm._v("Categoría")]),_c('p',{staticClass:"ma-0 mb-1"},[_vm._v(" Selecciona la categoría a la cual pertenece tu producto ")])]),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('v-slide-x-reverse-transition',{attrs:{"leave-absolute":""}},[(
            _vm.getRootCategories.length > 0 && _vm.stepCategorie === 1 && !_vm.loader
          )?_c('v-card',{staticClass:"overflow-y-auto mb-2",attrs:{"max-height":"300","outlined":""}},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.getRootCategories),function(categorie,index){return _c('v-list-item',{key:categorie.id,on:{"click":function($event){return _vm.nextCategory(
                    categorie.id,
                    index,
                    _vm.getRootCategories.length,
                    categorie
                  )}}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(categorie.name)}})],1)],1)}),1)],1)],1):_vm._e()],1),_c('v-slide-x-reverse-transition',{attrs:{"leave-absolute":""}},[(
            !_vm.loader &&
              _vm.stepCategorie === 2 &&
              _vm.getSubCategories(_vm.subCategoryID).length > 0
          )?_c('v-card',{staticClass:"overflow-y-auto mb-2",attrs:{"max-height":"300","outlined":""}},[_c('p',{staticClass:"font-weight-bold mt-2 mb-0 text-center"},[_vm._v(" \""+_vm._s(_vm.titleCategory)+"\" ")]),_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.getSubCategories(_vm.subCategoryID)),function(categorie,index){return _c('v-list-item',{key:categorie.id,on:{"click":function($event){_vm.nextSubCategory(
                    index,
                    _vm.getSubCategories(_vm.subCategoryID).length,
                    categorie
                  )}}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(categorie.name)}})],1)],1)}),1)],1)],1):_vm._e()],1),_c('v-slide-x-reverse-transition',{attrs:{"leave-absolute":""}},[(!_vm.loader && _vm.stepCategorie === 3)?_c('v-card',{staticClass:"overflow-y-auto mb-2",attrs:{"max-height":"300","outlined":""}},[_c('v-container',{staticClass:"text-center"},[(_vm.categorie.picture)?_c('v-img',{staticStyle:{"margin":"0 auto"},attrs:{"src":_vm.categorie.picture,"width":"80","height":"80"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1)]},proxy:true}],null,false,640922289)}):_vm._e(),_c('h4',{staticClass:"text-subtitle-1 mb-2"},[_vm._v(_vm._s(_vm.categorie.name))]),_c('v-btn',{attrs:{"small":"","dark":""},on:{"click":function($event){_vm.stepCategorie = 1}}},[_vm._v("cambiar")])],1)],1):_vm._e()],1),(_vm.loader)?_c('v-card',{staticClass:"d-flex align-center justify-center",attrs:{"height":"300","elevation":"0"}},[_c('p',{staticClass:"ma-0 mt-5"},[_vm._v("Cargando categorias...")]),_c('app-absolute-loader',{attrs:{"progress":_vm.loader,"color":_vm.$vuetify.theme.dark ? '' : 'grey'}})],1):_vm._e(),(!_vm.loader)?_c('div',{staticClass:"text-center"},[(_vm.stepCategorie === 2)?_c('v-btn',{staticClass:"ma-2",attrs:{"small":""},on:{"click":_vm.goBackCategory}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"20"}},[_vm._v("arrow_back")]),_vm._v(" regresar")],1):_vm._e()],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }